import { createContext, ReactNode, useContext, useReducer } from 'react'
import { Dispatch, initialState, State, wizardReducer } from './WizardReducer'

type WizardProviderProps = { children: ReactNode }

const WizardContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

export function WizardProvider({ children }: WizardProviderProps) {
  const [state, dispatch] = useReducer(wizardReducer, initialState)

  const value = { state, dispatch }

  return (
    <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
  )
}

export function useWizard() {
  const context = useContext(WizardContext)

  if (context === undefined) {
    throw new Error('No context was propagated')
  }

  return context
}
