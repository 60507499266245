// import { operations } from 'driverama-core/api/driverama/generated/bpmCalculator'
import { NoteCreateResponse } from 'api/driverama/note/create'

export type CalendarFilters = 'buying' | 'handover' | 'reclamation'

type Action =
  | {
      type: 'SET_BPM'
      payload: {
        priceFrom: number
        priceTo: number
        customerExpectedPrice: number
      }
    }
  | {
      type: 'SET_BRANCH'
      payload: string
    }
  | {
      type: 'RESET_CUSTOMER'
    }
  // | {
  //     type: 'SET_BPM_RESULT'
  //     payload: operations['calculateSearch']['responses']['200']['content']['application/json'][0]['result']
  //   }
  | {
      type: 'RESET_BPM'
    }
  | {
      type: 'SET_LICENSE_PLATE'
      payload: string
    }
  | {
      type: 'SET_SLOT'
      payload: {
        start: Date
        end: Date
      }
    }
  | {
      type: 'SET_OPPORTUNITY_ID'
      payload: string
    }
  | {
      type: 'SET_CUSTOMER_ID'
      payload: string | undefined
    }
  | {
      type: 'RESET_STORE'
    }
  | {
      type: 'RESET_SLOT'
    }
  | {
      type: 'SET_IS_LOADING'
      payload: boolean
    }
  | {
      type: 'SET_CALENDAR_DATE'
      payload: Date
    }
  | {
      type: 'SET_LATEST_CREATED_ID'
      payload: string | undefined
    }
  | {
      type: 'SET_FILTERS'
      payload: CalendarFilters[]
    }
  | {
      type: 'SET_EMPLOYEE_FILTER'
      payload: string[]
    }

export type Dispatch = (action: Action) => void

export type Slot = {
  start: Date
  end: Date
}

export type State = {
  calendarDate?: Date
  opportunityId?: string
  licensePlate?: string
  branchId?: string
  bpm?: {
    priceFrom: number
    priceTo: number
    customerExpectedPrice: number
  }
  // bpmResult?: operations['calculateSearch']['responses']['200']['content']['application/json'][0]['result']
  slot?: Slot
  customerId?: string
  note?: NoteCreateResponse
  latestCreatedId?: string
  isLoading: boolean
  calendarFilters: CalendarFilters[]
  employeeFilters: string[]
}

export const initialState: State = {
  isLoading: false,
  calendarFilters: ['buying', 'handover', 'reclamation'],
  employeeFilters: []
}

export function wizardReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_BPM':
      return {
        ...state,
        bpm: action.payload
      }

    case 'RESET_BPM':
      return {
        ...state,
        licensePlate: undefined,
        // bpmResult: undefined,
        bpm: undefined
      }

    case 'SET_CUSTOMER_ID':
      return {
        ...state,
        customerId: action.payload
      }

    case 'RESET_CUSTOMER':
      return {
        ...state,
        customerId: undefined
      }

    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload
      }

    // case 'SET_BPM_RESULT':
    //   return {
    //     ...state,
    //     bpmResult: action.payload
    //   }

    case 'SET_LICENSE_PLATE':
      return {
        ...state,
        licensePlate: action.payload
      }

    case 'SET_BRANCH':
      return {
        ...state,
        branchId: action.payload
      }

    case 'SET_SLOT':
      return {
        ...state,
        slot: {
          ...action.payload
        }
      }

    case 'RESET_SLOT':
      return {
        ...state,
        slot: undefined
      }

    case 'RESET_STORE':
      return initialState

    case 'SET_OPPORTUNITY_ID':
      return {
        ...state,
        opportunityId: action.payload
      }

    case 'SET_CALENDAR_DATE':
      return {
        ...state,
        calendarDate: action.payload
      }

    case 'SET_LATEST_CREATED_ID':
      return {
        ...state,
        latestCreatedId: action.payload
      }

    case 'SET_FILTERS': {
      return {
        ...state,
        calendarFilters: action.payload
      }
    }

    case 'SET_EMPLOYEE_FILTER': {
      return {
        ...state,
        employeeFilters: action.payload
      }
    }

    default:
      return initialState
  }
}
