import { css, Global } from '@emotion/react'
import { LINKS } from 'constants/links'
import {
  AuthAppProps,
  AuthRedirectHead,
  AuthRedirectType
} from 'driverama-core/auth/next'
import { global } from 'driverama-core/styles/global'
import { size } from 'driverama-core/styles/spacing'
import { color, font, variables } from 'driverama-core/styles/variables'
import { authCookies } from 'driverama-core/utils/auth'
import { createIntlClient } from 'driverama-core/utils/intl'
import { resolveRedirectDestination } from 'driverama-core/utils/page'
import { DefaultSeo } from 'next-seo'
import Head from 'next/head'
import { Router, useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useRef } from 'react'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Hydrate } from 'react-query/hydration'
import { UIDReset } from 'react-uid'
import { WizardProvider } from 'sections/wizard/WizardContext'

if (
  process.env.NEXT_PUBLIC_ENABLE_MOCKING === 'true' &&
  typeof window !== 'undefined'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('../tools/mocks/browser')
  worker.start()
}

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeError', () => NProgress.done())
Router.events.on('routeChangeComplete', () => NProgress.done())

function AppSeo() {
  const { asPath } = useRouter()
  const { t } = useTranslation(['core'])
  return (
    <DefaultSeo
      titleTemplate="%s | Driverama"
      defaultTitle="Driverama"
      openGraph={{
        url: asPath,
        // TODO: get current locale
        locale: 'en_GB'
      }}
      facebook={{
        appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string
      }}
      description={t('core:seo_description')}
      // TODO: we need twitter account for this
      // twitter={{ handle: '', site: '' }}
    />
  )
}

function App({ Component, pageProps }: AuthAppProps) {
  const queryClientRef = useRef<QueryClient>()
  const router = useRouter()

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient()
  }

  const i18nClient = createIntlClient(pageProps.i18n)
  const authRedirectType = Component.auth?.type ?? AuthRedirectType.None

  return (
    <I18nextProvider i18n={i18nClient}>
      {authRedirectType !== AuthRedirectType.None && (
        <AuthRedirectHead
          type={authRedirectType}
          cookies={authCookies}
          destination={
            resolveRedirectDestination(Component.auth, router.query) ??
            LINKS.login
          }
        />
      )}
      <QueryClientProvider client={queryClientRef.current}>
        <Hydrate state={pageProps.dehydratedState}>
          {process.env.NEXT_PUBLIC_ENABLE_REACT_QUERY_DEVTOOLS === 'true' && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}

          <Global
            styles={css`
              ${global.fonts};
              ${global.reset};
              ${global.nprogress};
              ${variables};

              body {
                font-family: ${font('text')};
                color: ${color('night-text')};
              }

              html {
                scroll-padding-top: ${size(18)};
              }

              #nprogress {
                color: ${color('night')};
              }
            `}
          />
          <UIDReset>
            <Head>
              <link rel="icon" href="/icon.svg" />
              <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
              <link rel="manifest" href="/site.webmanifest" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"
              />
            </Head>
            <AppSeo />
            <WizardProvider>
              <Component {...pageProps} />
            </WizardProvider>
          </UIDReset>
        </Hydrate>
      </QueryClientProvider>
    </I18nextProvider>
  )
}

export default App
